// Tools
import { isArray, isObject } from 'lodash'
import moment from 'moment'
import dayjs from 'dayjs'

export default (filterParams, joinArr = true) => {
  console.info('formatParams', filterParams)
  const params = {}
  Object.keys(filterParams).map(key => {
    if (!filterParams[key]) return
    const type = Object.prototype.toString.call(filterParams[key])
    switch (type) {
      case '[object Array]':
        if (!filterParams[key].length) return
        params[key] = filterParams[key].map((item) => isObject(item) ? item.value : item)
        if (joinArr) params[key] = params[key].join(',')
        break
      case '[object Object]':
        params[key] = filterParams[key].value
        break
      case '[object Date]':
        params[key] = moment(filterParams[key]).format('YYYY-MM-DD HH:mm' + (/start|begin/.test(key.toLocaleLowerCase()) ? ':00' : ':59'))
        if (/end/.test(key.toLocaleLowerCase()) && dayjs(filterParams[key]).format('H') == 0 && dayjs(filterParams[key]).format('m') == 0) {
          params[key] = moment(filterParams[key]).format('YYYY-MM-DD 23:59:59')
        }
        break
      default:
        params[key] = filterParams[key]
    }
  })
  return params
}
