
import { queryMaterial } from '@/services/specialCar.js'
import baseDataServices from '@/services/baseDataServices'
import { arrayFormat } from '@/utils/index.js'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      seriesList:[], // 车系列表
      modelList:[], // 车型
      outsideColours:[], // 外饰
      innerColours:[], // 内饰
      saleOptions:[], // 选装

      seriesListDict:[], // 车系列表
      modelListDict:[], // 车型
      outsideColoursDict:[], // 外饰
      innerColoursDict:[], // 内饰
      saleOptionsDict:[], // 选装
      
      dccStaffList: [],
      materialList: [],
      specialVehicles: [
        { id: '40551002', label: '质损车', value: '40551002' },
        { id: '40551003', label: '老龄车', value: '40551003' },
        { id: '40551004', label: 'Ni80车型', value: '40551004' },
      ],
      statusList: [
        { id: 5, label: '已生效', value: 5 },
        { id: 10, label: '已失效', value: 10 },
        { id: 15, label: '已关联订单', value: 15 },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
  },
  props: {
    commonFilterParams: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    commonFilterParams: {
      handler() {
        this.onRefresh && this.onRefresh()
      },
      deep: true,
    },
  },
  methods: { 
    async getStaffList() {
      const dccStaffList = await baseDataServices.getStaffList({ dealerId: this.userInfo.dealers[0]?.id })
      this.dccStaffList = dccStaffList.map((item) => ({ ...item, label: item.name, value: item.id }))
    },
    async getMaterialList() {
      const res = await queryMaterial().then(res=>arrayFormat(res,{
        value: 'code',
        cb:(item)=>({ id:item.code })
      }))
      this.materialList = res
      // 车系
      const seriesList = this.uniqueByProperty(res, 'seriesCode')
      this.seriesList = seriesList.map(item => {
        return {
          ...item,
          id: item.seriesCode,
          value: item.seriesCode,
          label: item.seriesName,
        }
      })
      this.seriesListDict = seriesList.map(item => {
        return {
          ...item,
          dictCode: item.seriesCode,
          dictName: item.seriesName,
        }
      })
      // 车型
      const modelList = this.uniqueByProperty(res, 'modelCode')
      this.modelList = modelList.map(item => {
        return {
          ...item,
          id: item.modelCode,
          value: item.modelCode,
          label: item.modelName,
        }
      })
      this.modelListDict = modelList.map(item => {
        return {
          ...item,
          dictCode: item.modelCode,
          dictName: item.modelName,
        }
      })
      // 外饰
      const outsideColours = this.uniqueByProperty(res, 'colourCode')
      this.outsideColours = outsideColours.map(item => {
        return {
          ...item,
          id: item.colourCode,
          value: item.colourCode,
          label: item.colourName,
        }
      })
      this.outsideColoursDict = outsideColours.map(item => {
        return {
          ...item,
          dictCode: item.colourCode,
          dictName: item.colourName,
        }
      })
      // 内饰
      const innerColours = this.uniqueByProperty(res, 'interiorCode')
      this.innerColours = innerColours.map(item => {
        return {
          ...item,
          id: item.interiorCode,
          value: item.interiorCode,
          label: item.interiorName,
        }
      })
      this.innerColoursDict = innerColours.map(item => {
        return {
          ...item,
          dictCode: item.interiorCode,
          dictName: item.interiorName,
        }
      })
      // 选装
      let optionList = res.flatMap(item => {
        if (item.optionList) { 
          // return [ ...item.optionList ]
          return item.optionList.map(item2 => {
            return {
              ... item, ...item2
            }
          })
        }
        return [item]
      })
      const saleOptions = this.uniqueByProperty(optionList, 'optionsCode')
      this.saleOptions = saleOptions.map(item => {
        return {
          ...item,
          id: item.optionsCode,
          value: item.optionsCode,
          label: item.optionsName,
        }
      })
      this.saleOptionsDict = saleOptions.map(item => {
        return {
          ...item,
          dictCode: item.optionsCode,
          dictName: item.optionsName,
        }
      })
      // await this.createFilterOptions()
    },
    // 对象数组去重
    uniqueByProperty(arr, prop) {
      return [...new Map(arr.map(item => [item[prop], item])).values()]
    },
    // 重复元素打标记isTag=true
    markDuplicates(arr, prop) {
      const nameMap = {}
      arr.forEach(item => {
        const itemName = item[prop]
        if (nameMap[itemName]) {
          item.isTag = true
        } else {
          nameMap[itemName] = 1
          item.isTag = false
        }
      })
      return arr
    },
    async controlModelSales(queryType = 1, params = {}) {
      switch (queryType) {  
        case 1:
          this.getMaterialList()
          break  
        case 2: // 车型
          this.modelSalesQuery(2,params)
          this.modelSalesQuery(3,params)
          this.modelSalesQuery(4,params)
          this.modelSalesQuery(5,params)
          break
        case 3:// 外饰
          this.modelSalesQuery(3,params)
          this.modelSalesQuery(4,params)
          this.modelSalesQuery(5,params)
          break
        case 4:// 内饰
          this.modelSalesQuery(4,params)
          this.modelSalesQuery(5,params)
          break
        case 5:// 选装
          this.modelSalesQuery(5,params)
          break
      }
    },
    async modelSalesQuery(queryType,params={}) {
      const seriesCodesList = params.seriesCodes?.length > 0 ? params.seriesCodes : []
      const modelCodesList = params.modelCodes?.length > 0 ? params.modelCodes : []
      const colourCodesList = params.colourCodes?.length > 0 ? params.colourCodes : []
      const interiorCodesList = params.interiorCodes?.length > 0 ? params.interiorCodes : []
      const codeList = params.interiorCodes?.length > 0 ? params.interiorCodes : 
      params.colourCodes?.length > 0 ? params.colourCodes :
      params.modelCodes?.length > 0 ? params.modelCodes : 
      params.seriesCodes?.length > 0 ? params.seriesCodes : []
      const codeType = params.interiorCodes?.length > 0 ? 'interiorCode' : 
      params.colourCodes?.length > 0 ? 'colourCode' :
      params.modelCodes?.length > 0 ? 'modelCode' : 
      params.seriesCodes?.length > 0 ? 'seriesCode' : ''
      let list2 = []
      let list3 = []
      let list4 = []
      let optionList = []
      let modelList = []
      let outsideColours = []
      let innerColours = []
      let saleOptions = []

      let outsideColours2 = []
      let innerColours2 = []
      let optionList2 = []
      switch (queryType) {    
        case 2: // 车型
          list2 = [...this.materialList]
          modelList = this.uniqueByProperty(list2, 'modelCode')
          if (codeList?.length > 0) {
            this.modelList = modelList.filter(item => {
              if (codeList.includes(item[codeType])) {
                item.id = item.modelCode
                item.value = item.modelCode
                item.label = item.modelName
                return true
              }
            })
            this.modelListDict = modelList.filter(item => {
              if (codeList.includes(item[codeType])) {
                item.dictCode = item.modelCode
                item.dictName = item.modelName
                return true
              }
            })
          } else {
            this.modelList = modelList.map(item => {
              return {
                ...item,
                id: item.modelCode,
                value: item.modelCode,
                label: item.modelName,
              }
            })
            this.modelListDict = modelList.map(item => {
              return {
                ...item,
                dictCode: item.modelCode,
                dictName: item.modelName,
              }
            })
          }
          break
        case 3:// 外饰
          // list3 = JSON.parse(JSON.stringify(this.materialList))
          list3 = [...this.materialList]
          outsideColours = this.markDuplicates(list3, 'colourCode')
          if (codeList?.length > 0) {
            list3.filter(item => {
              if (codeList.includes(item[codeType]) && 
              (seriesCodesList.length > 0 ? seriesCodesList.includes(item.seriesCode) : true) && 
              (modelCodesList.length > 0 ? modelCodesList.includes(item.modelCode) : true)) {               
                outsideColours2.push(item)
              }
            })
            outsideColours2 = this.uniqueByProperty(outsideColours2, 'colourCode')
            this.outsideColours = outsideColours2.map(item => {
              return {
                ...item,
                id: item.colourCode,
                value: item.colourCode,
                label: item.colourName,
              }
            })
            this.outsideColoursDict = outsideColours2.map(item => {
              return {
                ...item,
                dictCode: item.colourCode,
                dictName: item.colourName,
              }
            })
          } else {
            this.outsideColours = outsideColours.map(item => {
              return {
                ...item,
                id: item.colourCode,
                value: item.colourCode,
                label: item.colourName,
              }
            })
            this.outsideColoursDict = outsideColours.map(item => {
              return {
                ...item,
                dictCode: item.colourCode,
                dictName: item.colourName,
              }
            })
          }
          break
        case 4:// 内饰
          list4 = [...this.materialList]
          innerColours = this.uniqueByProperty(list4, 'interiorCode')
          if (codeList?.length > 0) {
            list4.filter(item => {
              if (codeList.includes(item[codeType]) && 
              (seriesCodesList.length > 0 ? seriesCodesList.includes(item.seriesCode) : true) && 
              (modelCodesList.length > 0 ? modelCodesList.includes(item.modelCode) : true) && 
              (colourCodesList.length > 0 ? colourCodesList.includes(item.colourCode) : true)) {               
                innerColours2.push(item)
              }
            })
            innerColours2 = this.uniqueByProperty(innerColours2, 'interiorCode')
            this.innerColours = innerColours2.map(item => {
              return {
                ...item,
                id: item.interiorCode,
                value: item.interiorCode,
                label: item.interiorName,
              }
            })
            this.innerColoursDict = innerColours2.map(item => {
              return {
                ...item,
                dictCode: item.interiorCode,
                dictName: item.interiorName,
              }
            })
          } else {
            this.innerColours = innerColours.map(item => {
              return {
                ...item,
                id: item.interiorCode,
                value: item.interiorCode,
                label: item.interiorName,
              }
            })
            this.innerColoursDict = innerColours.map(item => {
              return {
                ...item,
                dictCode: item.interiorCode,
                dictName: item.interiorName,
              }
            })
          }
          break
        case 5:// 选装
          optionList = this.materialList.flatMap(item => {
            if (item.optionList) { 
              // return [ ...item.optionList ]
              return item.optionList.map(item2 => {
                return {
                  ... item, ...item2,
                  isTag: false
                }
              })
            }
            return [item]
          })
          // saleOptions = this.uniqueByProperty(optionList, 'optionsCode')
          saleOptions = this.markDuplicates(optionList, 'optionsCode')
          if (codeList?.length > 0) {
            saleOptions.filter(item => {
              if (codeList.includes(item[codeType]) && 
              (seriesCodesList.length > 0 ? seriesCodesList.includes(item.seriesCode) : true) && 
              (modelCodesList.length > 0 ? modelCodesList.includes(item.modelCode) : true) && 
              (colourCodesList.length > 0 ? colourCodesList.includes(item.colourCode) : true) && 
              (interiorCodesList.length > 0 ? interiorCodesList.includes(item.interiorCode) : true)) {               
                optionList2 = [...optionList2, { ...item.optionList, ...item }]
              }
            })
            optionList2 = this.uniqueByProperty(optionList2, 'optionsCode')
            this.saleOptions = optionList2.map(item => {
              return {
                ...item,
                id: item.optionsCode,
                value: item.optionsCode,
                label: item.optionsName,
              }
            })
            this.saleOptionsDict = optionList2.map(item => {
              return {
                ...item,
                dictCode: item.optionsCode,
                dictName: item.optionsName,
              }
            })
          } else {
            this.saleOptions = saleOptions.map(item => {
              return {
                ...item,
                id: item.optionsCode,
                value: item.optionsCode,
                label: item.optionsName,
              }
            })
            this.saleOptionsDict = saleOptions.map(item => {
              return {
                ...item,
                dictCode: item.optionsCode,
                dictName: item.optionsName,
              }
            })
          }
          break
      }
    },
    filterArr (arr1, arr2) {
      return arr1.filter(t => {
        return arr2.includes(t)
      })
    },
  },
}
